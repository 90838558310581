import React from "react"
import styled from "styled-components"

const Root = styled.section`
  flex: 1;
  width: 100vw;
  height: 400px;
  @media only screen and (min-aspect-ratio: 16/14) and (min-width: 700px) {
  }
`

const Card = styled.div`
  @media only screen and (min-aspect-ratio: 16/14) and (min-width: 700px) {
    width: 70%;
  }
`

const IntroSection = () => {
  return <Root></Root>
}

export default IntroSection
